.volume {
    width: 100%;
    max-width: 150px;
}

.soundDiv {
    display: flex;
    gap: 15px;
    font-size: 20px;
    align-items: right;
    width: 100%;
    justify-content: end;
    width: "20%";
}

.pauseplay {
    font-size: 35px;
    transition: transform 0.2s;
}

.pauseplay:hover {
    transform: scale(1.1);
}

.forback {
    font-size: 16px;
    transition: transform 0.2s;
}

.forback:hover {
    transform: scale(1.4);
}

.buttons {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 5px;
}

.progress {
    width: 100%;

}

.progress .ant-slider-track {
    background-color: #A7A7A7;
}

.progress .ant-slider-handle {
    background-color: transparent;
}

.cover {
    width: 80px;
}

.footNumberHeader {
    display: inline;
    justify-content: left;
    width: 10%;
    white-space: nowrap;
    overflow: hidden;
}

.songTitle {
    font-size: 16px;
    color: white;
    /* white-space: nowrap; */
    inline-size: 150%;
    margin-top:-20px;
}

.songAlbum {
    font-size: 12px;
}

.middlePart {
    width: 50%;
    padding-left: 5%;
}


@media (max-width: 750px) {


    .soundDiv {
        /* visibility: hidden;
        margin-top:-120px;
        width: 0px; */
        display: none;
        /* padding-right: 500px; */
    }

    .songTitle {
        font-size: 16px;
        color: white;
        /* white-space: nowrap; */
        inline-size: 175%;
        margin-top: -30px;
    }


    .middlePart {
        width: 60%;
        padding-right: 5%;

    }

    /* .buttons {

        gap: 7px;
    } */

}

@media (max-width: 450px) {

    .cover {
        height: 100%;
        width: 70px;
    }

    .middlePart {
        padding-right: 0%;
        padding-left: 10%;
    }

    .songTitle {
        margin-top:-5px;
        inline-size: 125%;
    }


}