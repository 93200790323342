button {
  /* background: brown; */
  border: thin !important;
  padding: 10;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: rgb(0, 0, 0);

  cursor: pointer;
}

.logIn {
  background-color: #8400d0;
  color: white;
  font-size: 17px;
  height: 50px;
  width: 120px;
  border-radius: 15px;
  margin: 10px;
  margin-left: 20px;

}

.logOut {
  background-color: #8400d0;
  color: white;
  font-size: 17px;
  height: 50px;
  width: 120px;
  border-radius: 15px;
    margin: 10px;
    margin-left: 20px;

}

.deleteLocalFiles {
  background-color: #8400d0;
  color: white;
  font-size: 17px;
  height: 50px;
  /* width: 120px; */
  border-radius: 15px;
  margin: 10px;
  margin-left: 20px;
}