
.text{
  color: white;
}




.link {
  margin: 20px;
}

.featuredTitle {
  color: white;
  font-size: 35px;
  font-weight: 600;
  justify-content: center;
  display: flex;
  letter-spacing: 2px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.albums {
  /* color: white; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  /* max-width: 1000px; */
  width: 100%;
  gap: 20px;
  row-gap: 50px;
  -webkit-box-pack: start;

}



.img{
  width: 150px;
}





 

.titleText{
  width: 135px;
  /* font-size: 10px; */
}


.albumSelection {
  color:rgb(205, 203, 203);
  text-align: center;
  transition: transform .4s;
}

.albumSelection:hover {
  color: white;
  transform: scale(1.05);
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: #8400d0;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}


@media (max-width: 740px) {
  .img{
    width: 140px;
  }
  .titleText{
    width: 140px;
  }
  .albums{
    gap: 17px;
    row-gap: 40px;
  }

}

@media (max-width: 691px) {
  .img{
    width: 150px;
  }
  .titleText{
    width: 150px;
  }
  .albums{
    gap: 15px;
  }


  
  .Discord, .Twitter, .GitHub, .Medium, .Etherscan, .OpenSea{
    font-size: 0;
    color: transparent;
    ;
  }

}

@media (max-width: 561px){

  .img{
    width: 140px;
  }
  .titleText{
    width: 140px;
  }
}

@media (max-width: 531px) {


  /* .titleText{
    font-size: 12px;
    width: 150px;
  } */

  .featuredTitle{
    font-size:26px;
  }

  .albums{
    gap: 10px;
    row-gap: 5px;
  }

  .collapse{
    font-size:30px;

  }

  .collapse2{
    font-size:20px;
  }

}

@media (max-width: 390px){



  .img{
    width: 120px;
  }
  .titleText{
    width: 120px;
  }


  .featuredTitle{
    font-size:22px;
  }




}


@media (max-width: 360px) {

  .img{
    width: 115px;
  }

  .titleText{
    font-size: 13px;
    width: 115px;
  }

  .albums{
    gap: 10px;
    row-gap: 5px;
  }

  .featuredTitle{
    font-size:16px;
  }

}