.albumContent {
    padding: 30px 40px;
}


.buttonStyle {
    background-color: #8400d0;
    /* background: none; 
    border: none; */
    width: 100%;
    border-radius: 10px;
    min-width: 60px;
    max-width: 100px;
    font-family: arial, sans-serif;
    color: rgb(255, 243, 216);

    cursor: pointer;
}

.buttonMoreTokens {
    background-color: #8400d0;
    /* background: none; 
    border: none; */
    width: 100%;
    border-radius: 10px;
    min-width: 60px;
    max-width: 160px;
    font-family: arial, sans-serif;
    color: rgb(255, 243, 216);

    cursor: pointer;
}

.buttonRefreshTokens {
    background-color: #8400d0;
    /* background: none; 
    border: none; */
    width: 100%;
    border-radius: 10px;
    min-width: 60px;
    max-width: 110px;
    font-family: arial, sans-serif;
    color: rgb(255, 243, 216);

    cursor: pointer;

    margin-top: -100px;
    margin-right: 5px;
    margin-bottom: 5px;
}

button:hover {

    cursor: pointer;
    transform: scale(1.05);


}


.topBan {
    display: flex;
    gap: 30px;
}

.topBanButt {
    display: flex;
    gap: 30px;
}

/* .albumCover {
    width: 200px;
} */

video {
    width: 100%;
    /* margin-right: -200px; */
}


.albumCover {
    width: 80%;
    min-width: 10px;
    /* min-width: 250px; */
    /* max-width: 400px; */
    max-width: 40%;
    /* object-fit: cover;
    object-position: 100% 0; */
    object-fit: contain;


}

.albumDeets {
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: rgb(205, 203, 203);

}

.title {
    color: white;
    font-size: 40px;
    font-weight: bold;
    /* overflow: hidden; */
    /* white-space: nowrap; */
}

.artist {
    font-size: 20px;
    color: white;
}

.playButton {
    padding: 10px;
    background-color: #8400d0;
    width: 120px;
    min-width: 100px;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 35px;
    /* margin-bottom: 35px; */
    margin-bottom: 115px;
    border-radius: 25px;
    letter-spacing: 1.5px;
    transition: transform 0.4s;
}

.openButton {
    padding: 10px;
    background-color: transparent;
    width: 200px;
    /* min-width: 50px; */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 35px;
    /* margin-bottom: 35px; */
    margin-bottom: -250px;
    border: 2px solid white;
    border-radius: 25px;
    letter-spacing: 1.5px;
    transition: transform 0.4s;
}

.openLogo {
    height: 20px;
}

.playButton:hover {
    cursor: pointer;
    transform: scale(1.05);
    ;
}

.openButton:hover {
    cursor: pointer;
    transform: scale(1.05);
    ;
}

.tableHeader {
    border-bottom: 1px solid rgb(67, 67, 67);
    display: flex;
    color: rgb(125, 125, 125);
    letter-spacing: 1.4px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;


}

.numberHeader {
    display: flex;
    padding-left: 20px;
    justify-content: left;
    width: 10%;

}

.numberHeader1 {
    display: flex;
    padding-left: 46px;
    /* justify-content: right; */
    width: 10%;
}

.numberHeader2 {
    display: flex;
}

.titleHeader {
    width: 100%;
    /* padding-left: 15%; */
    margin-left: 15%;
    display: flex;
    /* justify-content: center; */

    white-space: nowrap;
    overflow: scroll;

}

.contractHeader {
    width: 100%;
    min-width: 80px;
    /* max-width: 150px; */
    /* padding-left: 15%; */
    margin-left: 15%;
    display: flex;
    /* justify-content: center; */
    margin-right: -30px;
    white-space: nowrap;
    overflow: scroll;

}

.contractText {
    width: 100%;
    min-width: 80px;
    /* max-width: 150px; */
    /* padding-left: 15%; */
    margin-left: 15%;
    display: flex;
    /* justify-content: center; */
    margin-right: -30px;
    white-space: nowrap;
    overflow: scroll;

}

.tableContent {
    border-bottom: 1px solid rgb(67, 67, 67);
    display: flex;
    color: rgb(125, 125, 125);
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 13px;
    padding-top: 13px;
}

.topBanButt2 {
    visibility: hidden;
    margin-bottom: -200px;
}

.OSButtons {

    margin-bottom: 100px;

}


.nextImage {
    /* padding: 10px; */
    background-color: #8400d0;
    width: 25px;
    color: white;
    /* display: flex; */
    /* justify-content: flex-end; */
    /* justify-content: center; */
    /* margin-left: -100px; */
    /* margin-left: -100%; */
    /* margin-bottom: -300px; */
    /* margin-bottom: -100%; */
    /* padding-top:25px;  */
    padding-top: 8%;
    /* padding-left: 95px; */
    /* padding-left: -50%; */
    z-index: 1;
    border-radius: 25px;
    letter-spacing: 1.5px;
    margin-right: -25px;
    /* transition: transform 0.4s; */
    /* float: right; */
    /* text-align: right; */
    /* align-items: right; */
}


.imageText1 {
    /* margin: 0 auto; */
    
    margin-bottom: -10px;
    
}

.imageText2 {
    
    margin-bottom: -20px;
    
}


.lyrics {
    /* white-space: pre-line; */
    white-space: pre-wrap;
    width: 800px;
}

@media (max-width: 1600px) {

    .contractHeader {
        width: 100%;
        min-width: 80px;
        /* max-width: 150px; */
        /* padding-left: 15%; */
        margin-left: 18%;
        display: flex;
        /* justify-content: center; */
        margin-right: -30px;
        white-space: nowrap;
        overflow: scroll;

    }

    .albumCover {
        width: 80%;
        /* min-width: 100px; */
        max-width: 400px;
        
    }

}

@media (max-width: 1260px) {

    .numberHeader {
        padding-left: 10px;
    }

    .numberHeader1 {
        display: flex;
        padding-left: 5%;
        width: 10%;
    }




}

@media (max-width: 960px) {

    .albumContent {
        padding: 10px 20px;
    }

    .buttonRefreshTokens {

        max-width: 85px;
        font-size: 10px;
  
    }


}

@media (max-width: 760px) {



    .albumCover {
        width: 80%;
        /* min-width: 100px; */
        max-width: 400px;
    }


    /* .albumDeets {
        width: 100%;
    }

    .title {
        width: 100%;
    }

    .artist {
        width: 100%;
    } */

    /* .topBan {
        display: initial
    } */

}

@media (min-width: 600px) {
    .imageText1 {
        font-size: 0px;
        margin-bottom: -10px;
        margin-right: -30px;
    }

    
}

@media (max-width: 600px) {


    .imageText2 {
        font-size: 0px;

        margin-bottom: 0px; 

    }

    .numberHeader {
        padding-left: 0px;
    }

    .albumDeets {
        width: 100%;
    }

    .title {
        width: 100%;
    }

    .artist {
        width: 100%;
    }

    .topBan {
        display: initial
    }

    .topBanButt {
        /* display: initial; */
        /* overflow: scroll; */
        gap: 10px;
    }

    .topBanButt2 {
        visibility: visible;
        margin-bottom: 20px;
    }

    .layer2Buttons {
        visibility: hidden;
        margin-left: -120px;
    }

    /* 
    .titleHeader {
        justify-content: space-evenly;
    } */

    .playButton {
        font-size: 13px;
        width: 30%;
        min-width: 100px;
        height: 45px;
        margin-top: 15px;
        margin-bottom: 10px;
        border-radius: 25px;

        padding: 12px;
    }

    .openButton {
        /* font-size: 13px; */
        width: 50%;
        min-width: 150px;

        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 25px;

    }

    .OSButtons {

        margin-bottom: 0px;

    }


}



@media(max-width:500px) {

    /* .titleHeader {

        white-space: nowrap;
        overflow: scroll;
        margin-left: 15%;
        padding-left: 0px;
    } */

    .albumContent {
        padding: 0px 20px;
    }




}


@media (max-width: 360px) {

    /* .albumCover {
        min-width: 150px;
    } */

    .titleHeader {
        justify-content: left;
        margin-left: 15%;
        padding-left: 0px;
    }

    .numberHeader2 {
        justify-content: left;
        margin-left: -2%;
    }

    .albumContent {
        padding: 5px 5px;
    }

    .playButton {
        /* font-size: 9px; */
        /* min-width: 70px; */
        /* padding: 15px; */
    }

    .openButton {
        /* font-size: 9px; */

    }




}