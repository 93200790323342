.openSeaButtonCreate {
  padding: 10px;
  background-color: transparent;
  /* width: 200px; */
  /* min-width: 50px; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-top: 35px; */
  /* margin-bottom: 35px; */
  /* margin-bottom: -250px; */
  border: 2px solid white;
  border-radius: 25px;
  letter-spacing: 1.5px;
  transition: transform 0.4s;
  /* font-size: 13px; */
  width: 50%;
  min-width: 150px;

  margin-top: 5px;
  margin-bottom: 5px;
  /* border-radius: 25px; */


}

.wizard {

  width: 30%;
  margin-left: 35%;
  /* justify-content: center; */

}

.contractSelection {
  color: rgb(205, 203, 203);
  text-align: center;

}

/* .copyImg:hover {
    color: white;
    transform: scale(1.05);
  } */

.copies {
  /* color: white; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  /* max-width: 1000px; */
  width: 100%;
  gap: 80px;
  row-gap: 50px;
  -webkit-box-pack: start;

}

.copyless {
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  margin: 0 auto;

  /* max-width: 1000px; */
  width: 100%;
  margin-bottom: 50px;
  gap: 80px;
  row-gap: 0px;
  -webkit-box-pack: start;

}

.copyImg {
  width: 20%;
  transition: transform .4s;
}

.copyDiv {
  width: 40%;
}

.copyTextDiv {
  padding-top: 2%;
  vertical-align: middle;
  color: white;
  margin-left: 22%;

}

.copyButton {
  background-color: #8400d0;
  color: white;
  font-size: 17px;
  width: min(25%, 200px);
}

.loadingIcon {
  margin-left: 50%;
  font-size: 70px;
}


@media (max-width: 940px) {

  /* .copyDiv{
    width:60%;
} */
  .copyButton {

    font-size: small;
    width: min(30%);
  }

  .copyTextDiv {
    padding-top: 0%;
    /* margin-top:-1%; */
    margin-left: 22%;
  }

  .loadingIcon {
    margin-left: 40%;
  }

}

@media (max-width: 740px) {

  .copyDiv {
    width: 60%;
  }

  .copyButton {

    font-size: medium;
    width: min(30%);
  }

  .wizard {
    width: 50%;
    margin-left: 25%;
  }

}

@media (max-width: 450px) {


  .copies {
    margin-left: -15%;
  }

  .copyButton {
    font-size: small;
    width: min(40%);
  }

  .copyTextDiv {
    padding-top: 0%;
    margin-top: -1%;
    margin-left: 32%;
  }


  .copyImg {
    width: 29%;
  }

  .loadingIcon {
    margin-left: 30%;
  }

  .wizard {
    width: 70%;
    margin-left: 15%;
  }

}