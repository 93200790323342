

@import '~antd/dist/antd.css';


 .collapse{
    background-color:transparent;
    font-size: 40px;
  }
  
  .collapse2 {
    font-size: 30px;
    background-color:transparent;
  }
  .collapse3{
    font-size: 15px;
    background-color:transparent;
  }



.logoAbout {
  margin-top:-8%; 
  margin-bottom: 0%;

    width: max(85%, 150px);
  }

/* .ant-layout-content {
    padding-top: 10px;
} */

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  
  color: white;
  padding-bottom: 0px;
  padding-top: 0px;

}
.collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  
  color: #cf6712;

}
.collapse2.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  
  color: #a303ff;
}

.ant-collapse-content-box{
  color: white;
  
} 
.ant-collapse-content{
    background-color:transparent;
}



.Discord{
  color: white;
  background-color:#5865F2;
  
}

.Twitter{
  color: white;
  background-color:#1D9BF0;
}

.Medium{
  color: rgb(0, 0, 0);
  background-color:#ffffff;
}

.GitHub{
  color: rgb(0, 0, 0);
  background-color:#F4CBB2;
}

.Etherscan{
  color: white;
  background-color:#21325B;
}

.OpenSea{
  color: white;
  background-color:#1868B7
  ;
}








.socials{
  width: 50px;
  height: 50px;
  font-size: 50px;
  vertical-align: middle;
  color: black;

}

 

@media (min-width:700px){
    .logoAbout {
        margin-top:-5%; 
        margin-bottom: -20%;
        
        /* display: block; */
        margin-left: max(49%, 250px);
        margin-right: 0;
        width: min(50%, 500px);
      }

}

@media (min-width:1700px){
  .logoAbout {
    margin-top:-100px; 
    margin-bottom: -300px;
  }  
}

@media (max-width: 740px) {

 
  .collapse2{
    margin-left:-2vh;
  }

  .collapse3{
    margin-left:-3vh;
  }
}

@media (max-width: 691px) {


  
  .Discord, .Twitter, .GitHub, .Medium, .Etherscan, .OpenSea{
    font-size: 0;
    color: transparent;
    ;
  }

}


@media (max-width: 531px) {

  .collapse{
    font-size:30px;

  }

  .collapse2{
    font-size:20px;
  }

}
