@import '~antd/dist/antd.css';


/* .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
  background-color:#000000;
} */

.p-link{
margin-bottom:5px;
margin-left: 10px;
font-size: 25px;
}

.sideLink{
  margin-left: 10px;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-sider {
  background-image: linear-gradient(#201e24, #000000);
  background-attachment: fixed;
  width: 800px;
  padding: 5px;
  padding-top: 15px;
  z-index: 1;
}



.ant-layout-content {
  background-image: linear-gradient(#343337, #030404);
  background-attachment: fixed;
}

.ant-layout-footer {
  background-color: #292929;
  padding-bottom: 4px;
  padding-top: 15px;
  z-index: 1;
  /* max-height: 150px; */
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 0px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
  text-shadow: 0 0 0.25px currentcolor;
  
}

.ant-tabs-tab-btn:focus {
  color: white;
}

.ant-tabs-tab {
  color: rgb(125, 125, 125);
  font-size: 18px;
  letter-spacing: 2px;
}

.ant-tabs-tab:hover {
  color: white;
}

.ant-tabs-ink-bar {
  background: #c55901;
  width: 10px;
}

.ant-slider-track {
  background-color: #8400d0;
}

.ant-slider:hover .ant-slider-track {
  background-color: #8400d0;
}

.ant-slider-handle {
  border: 0px
}

.ant-slider-rail {
  background-color: #3E3E3E;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.logo2 {
  width: 150px;
  margin-bottom: -10px;
  margin-top:-20px;
}

.logo3 {
  width: 50px;
  /* margin-bottom: -10px; */
  margin-top:-10px;
}

.logoSide {
  width: 40px;
  margin-bottom: 5px;
}

.searchBar {
  color: rgb(0, 0, 0);
  border-top: 2px solid rgb(125, 125, 125);
  border-bottom: 2px solid rgb(125, 125, 125);
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sideBar {
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  overflow: auto;
  /* position: fixed; */
}
::-webkit-scrollbar {
  display: none;
}


.recentPlayed {
  border-top: 2px solid rgb(125, 125, 125);
  /* border-bottom: 2px solid rgb(125, 125, 125); */
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.recentTitle {
  color: rgb(125, 125, 125);
  font-size: 15px;
  font-weight: 400;
}

.install {
  border-top: 2px solid rgb(125, 125, 125);
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  
}

.contentWindow {
  padding: 20px 0px;
}

.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292929;
  color: rgb(205, 203, 203) !important;
}

.sideBarButton {
  /* visibility: hidden; */
  font-size: 30px;
  margin-left: 8px;
 
}

.searchIcon{
  color: white;
}


.sideLogIn{
  background-color:#8400d0;
  color:white;
  font-size:17px;
height:28px;
width:120px;

border-radius: 25px;
}




@media (max-width: 800px) {
  .sideBar {
    position: fixed;
    height: 100%;
    /* padding: 10px 20px; */
  }

    .contentWindow {
    margin-left: 60px;
  }

  .ant-layout-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  

}

@media (max-height: 650px){

    .recentPlayed {
    justify-content: start;
    /* height: auto; */
  }

}


@media (max-width: 450px){

  .ant-layout-footer {
    padding-left: 5px;
    padding-right: 5px;
  }

  .contentWindow {
    padding: 20px 0px;
  }


}